import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { DEFAULT_ERROR_DISPLAY_TIME } from '../constants/constants';
import { SnackbarType } from '../enums/snackbar-type.enum';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private _snackBar: MatSnackBar) { }

  public openSnackBar(type: SnackbarType, message: string, action = 'Close', duration?: number): void {
    switch (type) {
      case SnackbarType.Error:
        if (duration) {
          this._snackBar.open(message, action, { duration: duration, panelClass: ['error-snackbar'] });
        } else {
          this._snackBar.open(message, action, { panelClass: ['error-snackbar'] });
        }
        break;
      case SnackbarType.Success:
        this._snackBar.open(message, action, { duration: duration ?? 2000, panelClass: ['success-snackbar'] });
        break;
      case SnackbarType.Warning:
        this._snackBar.open(message, action, { duration: duration ?? DEFAULT_ERROR_DISPLAY_TIME, panelClass: ['warning-snackbar'] });
        break;
      case SnackbarType.Info:
        this._snackBar.open(message, action, { duration: duration ?? DEFAULT_ERROR_DISPLAY_TIME, panelClass: ['info-snackbar'] });
        break;
      default:
        this._snackBar.open(message, action);
        break;
    }
  }
}
